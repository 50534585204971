import React from 'react'
import HomeMain from './HomeMain'


const Home = () => {
  return (
    <div>
      <HomeMain/>
    </div>
  )
}

export default Home
