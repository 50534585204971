import sermon1 from '../assets/images/sermon-1@1.5x.jpg'
import sermon2 from '../assets/images/sermon-2@1.5x.jpg'
import sermon3 from '../assets/images/sermon-3@1.5x.jpg'
import sermon4 from '../assets/images/sermon-4@1.5x.jpg'
import sermon5 from '../assets/images/sermon-5@1.5x.jpg'
import sermon6 from '../assets/images/sermon-6@1.5x.jpg'

export const sermon = [{
    id: "21",
    title: "God bless my home",
    preacher: "Rev Festus Olu-Alade",
    video_url: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
    background_image: sermon1,
    date: "2022-05-19",
    blockquote: "Trust in the LORD with all your heart, and do not lean on your own understanding. In all your ways acknowledge him, and he will make straight your paths.",
    bible_passage: "Proverbs 3:5-6 (ESV)",
}, {
    id: "22",
    title: "God bless me",
    preacher: "Rev Miracle Alao",
    video_url: "https://www.youtube.com/watch?v=5IGh09-DPX4",
    background_image: sermon2,
    date: "2022-05-19",
    blockquote: "Trust in the LORD with all your heart, and do not lean on your own understanding. In all your ways acknowledge him, and he will make straight your paths.",
    bible_passage: "Proverbs 3:5-6 (ESV)",
}, {
    id: "24",
    title: "God bless me",
    preacher: "Rev Paul Oydiran",
    video_url: "https://www.youtube.com/watch?v=5IGh09-DPX4",
    background_image: sermon3,
    date: "2022-05-19",
    blockquote: "Trust in the LORD with all your heart, and do not lean on your own understanding. In all your ways acknowledge him, and he will make straight your paths.",
    bible_passage: "Proverbs 3:5-6 (ESV)",
},{
    id: "25",
    title: "God bless me",
    preacher: "Rev Paul Oydiran",
    video_url: "https://www.youtube.com/watch?v=5IGh09-DPX4",
    background_image: sermon4,
    date: "2022-05-19",
    blockquote: "Trust in the LORD with all your heart, and do not lean on your own understanding. In all your ways acknowledge him, and he will make straight your paths.",
    bible_passage: "Proverbs 3:5-6 (ESV)",
},
{
    id: "26",
    title: "God bless me",
    preacher: "Rev Paul Oydiran",
    video_url: "https://www.youtube.com/watch?v=5IGh09-DPX4",
    background_image: sermon5,
    date: "2022-05-19",
    blockquote: "Trust in the LORD with all your heart, and do not lean on your own understanding. In all your ways acknowledge him, and he will make straight your paths.",
    bible_passage: "Proverbs 3:5-6 (ESV)",
},{
    id: "27",
    title: "God bless me",
    preacher: "Rev Paul Oydiran",
    video_url: "https://www.youtube.com/watch?v=5IGh09-DPX4",
    background_image: sermon6,
    date: "2022-05-19",
    blockquote: "Trust in the LORD with all your heart, and do not lean on your own understanding. In all your ways acknowledge him, and he will make straight your paths.",
    bible_passage: "Proverbs 3:5-6 (ESV)",
}]