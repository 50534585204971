import pastor from "../assets/images/pastors&diaconates/pastor@1.5x.jpg";
import staff1 from "../assets/images/pastors&diaconates/staff-1@1.5x.jpg";
import pastor1 from "../assets/images/pastors&diaconates/staff-1@1.6x.jpg";
import pastor2 from "../assets/images/pastors&diaconates/staff-1@1.7x.jpg";
import staff5 from "../assets/images/pastors&diaconates/staff-5@1.5x.jpg";
import staff7 from "../assets/images/pastors&diaconates/staff-7@1.5x.jpg";
import staff9 from "../assets/images/pastors&diaconates/staff-9@1.5x.jpg";
export const pastors = [
  
  {
    name: "Rev'd Dr. Festus Olu Ade Alade",
    disposition: "Church Pastor",
    image: pastor,
    email: "test@test.com",
    phoneNumber: "+2347067897717",
    whatsapp: "https://wa.me/+2347067897717",
    facebook: "https://web.facebook.com/festusolukay.alade",
  },
  {
    name: "Rev. Tunde Ajala",
    disposition: "Music Minister",
    image: staff1,
    email: "test@test.com",
    phoneNumber: "+23409168226161",
    whatsapp: "https://wa.me/+2348066770980",
    facebook: "https://web.facebook.com/ajala.babatundesimeon.1",
  },
  {
    name: "Rev. S. O Alamu",
    disposition: "Mission Coordinator",
    image: pastor1,
    email: "test@test.com",
    phoneNumber: "+23409168226161",
    whatsapp: "https://wa.me/+2348066770980",
    facebook: "https://web.facebook.com/ajala.babatundesimeon.1",
  },
  {
    name: "Rev. J.O Aderinto",
    disposition: "Mission Minister",
    image: pastor2,
    email: "test@test.com",
    phoneNumber: "+23409168226161",
    whatsapp: "https://wa.me/+2348066770980",
    facebook: "https://web.facebook.com/ajala.babatundesimeon.1",
  },
];
export const diaconates = [ 
  {
    name: "Dns. E. A. Ajina",
    image: staff5,
    disposition: "",
  },
   
  {
    name: "Dns. Dr. Bosede Oyedepo",
    image: staff9,
    disposition: "",
  },
  {
    name: "Dn. S.A Okikiade",
    image: staff7,
    disposition: "",
  },
];